@charset "UTF-8";
.login h1 a {
    background: url("../img/login-logo.png") no-repeat top center;
    width: 326px;
    height: 67px;
    background-size: 326px 67px;
    text-indent: -9999px;
    overflow: hidden;
    padding-bottom: 15px;
    display: block;
}
form {
    margin-left: 8px;
    padding: 26px 24px 46px;
    font-weight: normal;
    background: white;
    border: 1px solid #E5E5E5;
}
body form .input {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: normal;
    font-size: 24px;
    width: 97%;
    padding: 3px;
    margin-top: 2px;
    margin-right: 6px;
    margin-bottom: 16px;
    border: 1px solid #E5E5E5;
    background: #FBFBFB;
    outline: none;
}
